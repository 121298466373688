<template>
  <div class="container-fluid header">
    <div class="container">
      <div class="row">
        <div id="div_capa" class="col-md-2 col-sm-2 header-box-logo-evento" v-if="image != ''">
          <img :src="image" alt="logo evento">
        </div>
        <div class="col-md-9 col-sm-12 header-box-menu-evento">
          <ul class="pull-right">

            <li>
              <v-link :href="'/organizador/'+ organizer_id +'/'+ organizer_slug +'/'">
                <span class="glyphicon glyphicon-briefcase glyphcolor4 mr-1"></span> ORGANIZADOR
              </v-link>
            </li>

            <li class="a-carinho" v-if="user != ''">
              <span class="badge" v-if="carrinho != 0">{{carrinho}}</span>
              <span class="glyphicon glyphicon-shopping-cart"></span>
              <v-link className="nav-link" href="/carrinho/">Meu Carrinho</v-link>
            </li>

            <li>
              <v-link href="/conteudo/5/central-de-ajuda/">
                <span class="glyphicon glyphicon-star glyphcolor4 mr-1"></span> CENTRAL DE AJUDA
              </v-link>
            </li>

            <li class="a-conta" v-if="user == ''">
              <v-link className="nav-link" href="/cadastre-se/">
                <span class="glyphicon glyphicon-user glyphcolor4 mr-1"></span> CRIAR CONTA
              </v-link>
            </li>
            <li class="a-conta2" v-else>
              <span class="glyphicon glyphicon-user glyphcolor4"></span>Olá, {{ user.first_name }}<br />
              <v-link href="/minha-conta/">minha conta</v-link> /
              <v-link href="/sair/" @click.prevent="logout">sair</v-link>
            </li>

          </ul>
        </div>
        <div class="col-md-1 header-box-logo-tkx">
          <v-link href="/"><img src="/assets/img/logo-rodape-pb.png" alt="Ticket Experience"></v-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.badge {
  position: absolute;
  border-radius: 100%;
  background: #cf1e24;
  color: white;
  z-index: 1;
  margin-left: 13px;
  margin-top: -13px;
  font-size: 9px;
  padding: 4px 5px 3px;
}

.a-carinho .glyphicon{
  color: #d8c1c7;
  font-size: 20px;
  margin-top: -4px;
  margin-right: 7px;
}


</style>

<script>
import VLink from "../components/VLink.vue";
import Auth from "@/api/Auth";
import router from "@/router/router";
import Carrinho from "../api/Carrinho";

export default {
  name: "HeaderHome",
  props: {
    organizer_id: {
      type: Number,
      required: true
    },
    image: {
      type: String,
      required: false,
      default: "",
    },
    organizer_slug: {
      type: String,
      required: false
    },
    event:{
      required: false
    }
  },
  components: {
    VLink,
  },
  data() {
    return {
      FACEBOOK_LINK: process.env.VUE_APP_FACEBOOK_LINK,
      INSTAGRAN_LINK: process.env.VUE_APP_INSTAGRAN_LINK,
      user: "",
    };
  },
  methods: {
    logout() {
      Auth.logout();
      this.$root.currentRoute = "/";
      router.push("/");
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.user = Auth.user();
    window.addEventListener('user-logged-event', (event) => {
      this.user = event.detail.user;
    });
    window.addEventListener('user-logout-event', () => {
      this.user = '';
    });

    window.addEventListener('user-carrinho', () => {
      if(this.user != ''){
        let Itens =  Carrinho.get_itens();
        this.carrinho = Itens.length;
      }
    });

    if(this.user != ''){
      let Itens =  Carrinho.get_itens();
      this.carrinho = Itens.length;
    }

  },
};
</script>
