<template>
  <div class="countD" v-if="aberto === false && encerrado === false">
    <div class="d"><span>{{ days }}</span><br>DIAS</div>
    <div class="d"><span>{{ hours }}</span><br>HORAS</div>
    <div class="d"><span>{{ minutes }}</span><br>MIN</div>
    <div class="d"><span>{{ seconds }}</span><br>SEG</div>
  </div>
  <div class="ended-count" v-else-if="aberto === false && encerrado === true">
    INSCRIÇÕES ENCERRADAS
  </div>
  <div class="ended-count" v-else-if="aberto === true && encerrado === false">
    INSCRIÇÕES ABERTAS
  </div>
</template>

<script>
export default {
  name:'Countdown',
  components:{},
  data() {
    return {
      end: {},
      start: {},
      aberto:false,// Se ainda esta em aberto
      encerrado:false,
      days:0,
      hours:0,
      minutes:0,
      seconds:0,
      timer:{},
    }
  },
  props: {
    end_time: {
      type: String,
      required: true
    },
    start_time: {
      type: String,
      required: true
    },
  },
  methods:{
    run_countdown(){
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the countdown date
      var distance = this.start - now;

      // Time calculations for days, hours, minutes and seconds
      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);


      if(distance < 0){
        this.days = 0;
        this.hours = 0;
        this.minutes = 0;
        this.seconds = 0;
        this.aberto = true;
        this.encerrado = false;
      }

      let distance_end_inscricao = this.end - now;

      // If the countdown is finished, write some text
      if (distance_end_inscricao < 0) {
        clearInterval(this.timer);
        this.days = 0;
        this.hours = 0;
        this.minutes = 0;
        this.seconds = 0;
        this.aberto = false;
        this.encerrado = true;
      }
    }
  },
  updated() {
    this.end = (new Date(this.end_time)).getTime();
    this.start = (new Date(this.start_time)).getTime();


    if(this.end < (new Date()).getTime()){
      this.aberto = false;
      this.encerrado = true;
    }else{
      this.run_countdown();

      this.timer = setInterval(()=>{
        this.run_countdown();
      }, 1000);
    }

  }
}
</script>

<style>
.ended-count{
  font-family: 'MontserratLight',sans-serif;
  padding: 28.5px;
  background: #6A5050;
  color: #ffffff;
  margin-right: 1%;
  text-align: center;
  line-height: 1.2;
}
</style>
